'use client';

import { getLangDir } from 'rtl-detect';
import { defaultLocale } from '@/i18n/routing';
import { MaintenanceClouds } from '@/app/[locale]/maintenance/components/MaintenanceClouds';
import { Beardman404 } from '~/components/Beardman/Beardman404/Beardman404';
import { Logo } from '@/app/components/Logo';

export default function NotFound() {
  const direction = getLangDir(defaultLocale);

  return (
    <html lang={defaultLocale} dir={direction}>
      <body>
        <div className="maintenance-page">
          <header>
            <Logo />
          </header>
          <main
            className="main-content"
            id="maincontent"
            role="main"
          >
            <div className="maintenance-page__wrapper">
              <div className="maintenance-page__message">
                <h1 className="vp-title-m">404</h1>
                <p className="vp-body-l">
                  Nous n’arrivons pas à trouver la page que vous recherchez.
                </p>
                <Beardman404 />
                <MaintenanceClouds />
              </div>
            </div>
          </main>
        </div>
      </body>
    </html>
  );
}
